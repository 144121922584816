.btn-home {
    &--all {
        display: flex;
        flex-direction: column;
        width: 150px;
        font-weight: $font-weight-semibold;
        line-height: 1.25rem;
        text-transform: uppercase;

        &:hover {
            &:after {
                width: 100%;
            }
        }

        &:after {
            content: '';
            display: block;
            height: 3px;
            width: 50px;
            margin-top: 12px;
            transition: $duration;
        }

        svg {
            height: 36px;
            width: 36px;
            margin-bottom: 15px;
        }
    }

    &--shadow {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: $shadow;
        background-color: $color-white;
        border: 2px solid $color-white;
        text-transform: uppercase;
        line-height: 1.25rem;
        font-weight: $font-weight-semibold;
        text-align: center;

        &:hover {
            box-shadow: none;
        }
    }

    &--pictos {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        border: 3px solid $color-fourth;
        background-color: transparent;
        padding: 0;

        svg {
            fill: $color-second;
            height: 50px;
            width: 50px;
            transition: $duration;
        }

        &:hover,
        &:focus {
            background-color: $color-fourth;
            border-color: $color-fourth;

            svg {
                fill: $color-dark;
            }
        }
    }
}

.home-tetris {
    > .container {
        display: flex;
        justify-content: space-between;
    }

    &--bottom {
        padding: 85px 0 80px;

        > .container {
            align-items: flex-end;
        }
    }

    &__row {
        position: relative;
        flex: auto;
        padding: 0 0 85px;

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-color: $color-six;
            height: 945px;
            bottom: 0;
            z-index: -1;
        }

        &:before {
            width: 2000px;
            left: -2000px;
        }

        &:after {
            right: 0;    
            width: 100%;
        }

        &__half {
            display: flex;
        }
    }

    &__col {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        max-height: 620px;
        padding: 0 80px 60px 0;
        width: 100%;

        &:before,
        &:after {
            content: '';
            position: absolute;
            background-color: $color-five;
            height: 450px;
            bottom: 0;
            z-index: -1;
        }

        &:before {
            width: 2000px;
            left: -2000px;
        }

        &:after {
            right: 0;    
            width: 100%;
        }
    }
}

//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    width: calc((100% + 1170px) / 2 - 470px); //470px = width tools
    left: calc((100% - 1170px) / 2 + 470px); //470px = width tools
    max-width: 865px;
    margin-top: -590px;
    box-shadow: $shadow;

    &__pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 30px;
        bottom: 30px;
        z-index: 2;
        width: auto;

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            margin: 0 20px 0 0;
            transition: .25s;
            background-color: $color-fourth;
            opacity: 1;
            filter: drop-shadow(0px 0px 10px $color-black);
            border-radius: 0;

            &:hover {
                background-color: $color-white;
            }

            &-active {
                background-color: $color-white;
            }
        }
    }

    .slideshow {
        height: 590px;
        width: 100%;

        img, video {
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: top;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: 330px;
            bottom: 30px;
            width: 400px;
            height: auto;
            padding: 25px 30px;
            background: rgba(116, 211, 126, .85);
            color: $color-dark;
            box-shadow: $shadow;
        }

        &__title {
            font-size: $font-size--4;
            font-weight: $font-weight-medium;
            line-height: 1.5rem;
            margin: 0 0 5px;
        }

        &__description {
            line-height: 1.25rem;
        }

        &--video {
            img {
                display: none;
            }
        }
    }
}


//======================================================================================================
// Accès rapides TOP + BOTTOM
//======================================================================================================
.home-access {
    &__title {
        text-transform: uppercase;
        margin: 0 0 13px;
        font-size: 3.375rem;
        line-height: 1;

        span {
            font-size: 2.125rem;
            text-transform: lowercase;
            line-height: 2.125rem;
            font-weight: $font-weight-normal;
            display: block;
            color: $color-main;
        }
    }

    &__list {
        display: flex;
    }

    &__link {
        @extend .btn-home--shadow;       

        &:hover {
            border-color: $color-third;
        }
    }

    &__svg-container {
        margin-bottom: 10px;
        width: 110px;
        height: 65px;
    }

    &__label {
        text-transform: uppercase;
        text-align: center;
        font-weight: $font-weight-semibold;
        font-size: 1rem;
        line-height: 1.25rem;
        color: $color-text;
        max-width: 130px;
    }

    &--top {
        margin-top: 30px;

        .home-access {
            &__link {
                height: 140px;
                width: 178px;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &--bottom {
        margin-right: 90px;

        .home-access {
            &__list {
                flex-direction: column;
            }
    
            &__link {
                height: 145px;
                width: 230px;
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


//======================================================================================================
// Actualités
//======================================================================================================
.home-posts {
    margin-bottom: 100px;
    margin-top: 90px;

    &__title {
        text-transform: uppercase;
        margin: 0 0 20px;
        font-size: 3.375rem;
        line-height: 1;

        span {
            font-size: 2.125rem;
            text-transform: lowercase;
            line-height: 2.125rem;
            font-weight: $font-weight-normal;
            color: $color-second;
            display: block;
        }
    }

    &__pagination {
        position: relative;
        z-index: 2;
        display: flex;
        margin-bottom: 30px;

        .swiper-pagination-bullet {
            height: 15px;
            width: 15px;
            background-color: $color-second;
            margin-right: 20px;
            border-radius: 0;
            box-shadow: none;
            opacity: 1;
            transition: $duration;

            &-active,
            &:hover {
                background-color: $color-white;
                box-shadow: $shadow--variant;
            }
        }
    }

    &__container {
        display: flex;
    }

    .swiper-container {
        padding: 40px 20px;
        margin: -40px -20px;
    }

    &__swiper {
        width: 590px;
    }

    .featured-post {
        width: 280px;
        margin-right: 30px;
        transition: $duration;

        &:hover {
            .featured-post {
                &__image-container {
                    box-shadow: none;
                }

                &__more {
                    &:before {
                        background-color: $color-second;
                        margin-right: 15px;
                    }
                }
            }
        }

        &__image-container {
            width: 100%;
            height: 280px;
            background: url(/wp-content/themes/noyau/assets/images/icon-post.png) $color-gray center no-repeat;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            transition: $duration;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top;
            }
        }

        &__content {
            padding: 20px 0 0;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.5rem;
            margin: 0 0 5px;
            min-height: 50px;
        }

        &__introduction {
            line-height: 1.25rem;
            min-height: 80px;
        }

        &__more {
            display: flex;
            align-items: center;
            position: relative;
            margin-top: 15px;
            transition: $duration;

            &:before {
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                background-color: $color-fourth;
                margin-right: 10px;
                transition: $duration;
            }
        }
    }

    &__btn {
        @extend .btn-home--all;
        margin-left: 30px;
        margin-top: 175px;

        &:after {
            background-color: $color-fourth;
        }

        svg {
            fill: $color-second;
        }
    }
}


//======================================================================================================
// Agenda
//======================================================================================================
.home-events {
    flex: auto;
    margin-top: 90px;
    width: 200px;
    padding-left: 85px;

    &__title {
        display: flex;
        flex-direction: column;
        text-transform: uppercase;
        margin: 0 0 30px;
        line-height: 1;

        span {
            font-size: $font-size--4;
            text-transform: lowercase;
            line-height: 1.5rem;
            color: $color-main;
        }
    }
    
    &__container {
        position: relative;
        display: flex;
        flex-direction: column;

        &:after {
            content: '';
            position: absolute;
            background-color: $color-five;
            height: 518px;
            width: 2000px;
            right: -1930px; //width - margeTOcontainer = 2000-70
            top: 125px;
            z-index: -1;
        }
    }

    .featured-event {
        @extend .btn-home--shadow;
        align-items: flex-start;
        justify-content: flex-start;
        width: 200px;
        height: 211px;
        margin-bottom: 30px;
        padding: 15px 20px;

        &:hover {
            border-color: $color-third;
        }

        &__title {
            font-size: $font-size--4;
            line-height: 1.5rem;
            text-align: left;
            margin: 0;
            text-transform: none;
        }

        &__date {
            position: relative;
            display: flex;
            align-items: center;
            color: $color-main;
            padding-bottom: 12px;
            margin-bottom: 15px;

            &:after {
                content: '';
                position: absolute;
                display: block;
                height: 3px;
                width: 50px;
                bottom: 0;
                background-color: $color-third;
            }
            
            p {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            svg {
                width: 18px;
                height: 14px;
                margin: 0 20px;
                fill: $color-main;
            }

            &__day {
                font-size: $font-size--3;
                font-weight: $font-weight-bold;
                line-height: 1;
            }

            &__month {
                font-size: 1.125rem;
                font-weight: $font-weight-medium;
            }
        }
    }

    &__template-btn {
        margin-top: 5px
    }

    &__btn {
        @extend .btn-home--all;
        margin: 0 0 50px;

        svg {
            fill: $color-main;
        }

        &:after {
            background-color: $color-third;
        }

    }
}

//======================================================================================================
// Kiosque
//======================================================================================================
.home-docs {
    margin-right: 65px;

    .home-document {
        display: flex;

        &__container-image {
            width: 165px;
            height: 225px;
            background: url(/wp-content/themes/noyau/assets/images/icon-documents.png) $color-gray no-repeat center;
            margin-right: 30px;
            box-shadow: $shadow;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 210px;

            p {
                margin-bottom: 5px;
            }
        }

        &__title {
            font-size: $font-size--4;
            font-weight: $font-weight-medium;
            margin: 0 0 20px;
        }

        &__filesize {
            span {
                text-transform: uppercase;
            }
        }

        &__buttons {
            display: flex;
        }

        &__btn {
            @extend .btn-home--pictos;
            margin-right: 20px;

            &:last-child {
                margin-right: 0
            }
        }
    }

    &__btn-all {
        @extend .btn-home--all;
        margin-top: 40px;

        &:after {
            background-color: $color-fourth;
        }

        svg {
            fill: $color-second;
        }
    }
}


//======================================================================================================
// Annuaire
//======================================================================================================
.home-annuaire {
    width: 485px;

    &__title {
        margin: 30px 0 13px;
    }

    &__content {
        line-height: 1.25rem;
    }

    label {
        font-weight: $font-weight-normal;
        line-height: 1.25rem;
        margin: 0; 
    }

    &__wrapper-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 20px;

        select {
            appearance: none;
            display: flex;
            align-items: center;
            width: 415px;
            height: 50px;
            box-shadow: $shadow;
            background: $color-white url(/wp-content/themes/beganne/assets/src/images/sprite-images/home/arrow-dropdown.svg) no-repeat center;
            background-position: calc(100% - 15px) 50%;
            border-color: transparent;
            text-overflow : ellipsis;
            line-height: 1.5rem;
            padding: 0 15px;
            z-index: 1;

            &::-ms-expand {
                display: none;
            }

            &:focus-within {
                border: 1px solid $color-black;
            }
        }

        button {
            width: 92px;
            height: 92px;
            border-radius: $border-radius--round;
            margin: 0 0 0 -22px;
            padding: 0;
            background-color: rgba(37, 191, 246, .5);
            border: none;
            color: $color-text;
            text-transform: uppercase;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;

            &:hover {
                background-color: $color-main;
                color: $color-white;
            }
        }
    }


    &__template-btn {
        @extend .btn-home--all;
        margin: 25px 0 0;
        width: auto;
        display: inline-flex;

        &:after {
            background-color: $color-third;
        }

        svg {
            fill: $color-main
        }
    }
}

//======================================================================================================
// Newsletter
//======================================================================================================
.home-nl {
    &__link {
        @extend .btn-home--shadow;
        width: 260px;
        height: 225px;

        &:hover {
            border-color: $color-second;
        }

        svg {
            margin-bottom: 20px;
        }
    }
}

//======================================================================================================
// Partners
//======================================================================================================
.home-partners {    
    padding-left: 80px;

    &__list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 15px;
    }

    .partner {
        width: 200px;
        height: 100px;
        margin-top: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            img {
                opacity: .5;
            }
        }

        &__image {
            width: 100%;
            height: 100%;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            opacity: 1;
            transition: $duration;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-tetris {
        &__col {
            padding: 0 50px 60px 0;
        }
    }

    .home-slideshow {
        width: calc((100% + 940px) / 2 - 380px); //380px = width tools
        left: calc((100% - 940px) / 2 + 380px); //380px = width tools
        max-width: 690px;
        margin-top: -500px;

        .slideshow {
            height: 500px;

            &__content {
                left: 260px;
                width: 300px;
            }
        }
    }

    .home-posts {    
        &__pagination {
            margin-bottom: 40px;
        }
    
        &__swiper {
            width: 480px;
        }

        .swiper-container {
            padding: 40px 10px;
            margin: -40px -10px;
        }        
    
        .featured-post {
            width: 230px;
    
            &__image-container {
                height: 230px;
                box-shadow: 0 0 10px rgb(0 0 0 / 15%);
            }
        }
    
        &__btn {
            margin-left: 20px;
            margin-top: 125px;
        }
    }

    .home-events {
        padding-left: 40px;
        margin-top: 100px;
        
        &__container {    
            &:after {
                right: -1895px; //width - margeTOcontainer = 2000-105
                top: 130px;
            }
        }
    }

    .home-docs {
        margin-right: 25px;
    }

    .home-nl {
        &__link {
            width: 210px;

            span {
                max-width: 130px;
            }
        }
    }

    .home-access {
        &--top {
            margin-top: 20px;

            .home-access {
                &__link {
                    width: 160px;
                }
            }
        }

        &--bottom {
            margin-right: 50px;

            .home-access {
                &__link {
                    width: 160px;
                }
            }
        }
    }

    .home-annuaire {
        width: 440px;

        &__wrapper-select {
            select {
                width: 370px;
            }
        }
    }

    .home-partners {
        padding-left: 40px;
    }
}


// 960
@media screen and (max-width: $medium) {
    .btn-home {
        &--all {
            font-size: $font-size--text-small;
            line-height: 1.125rem;
        }
    }

    .home-tetris {
        &--bottom {
            padding: 95px 0 0;
        }

        &__col {
            padding: 0 0 60px;

            &:after {
                right: auto;
                left: 0;
                width: calc(100% + 2000px);
            }
        }
    }

    .home-slideshow {
        left: auto;
        width: 100%;
        margin: 40px auto 0;

        &__pagination {
            left: 35px;
        }

        .slideshow {
            &--video {
                video {
                    display: none;
                }
                img {
                    display: block;
                }
            }

            &__content {
                left: 355px;
            }
        }
    }

    .home-access {
        &--top {
            .home-access {
                &__list {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .home-tetris {
        >.container {
            flex-direction: column;
        }

        &__row {
            padding: 0 0 80px;

            &:before,
            &:after {
                height: 910px;
            }

            &:after {
                left: 0;
                right: auto;
                width: calc(100% + 2000px);
            }
        }
    }

    .home-posts {
        margin-bottom: 65px;
        margin-top: 70px;
        
        &__btn {
            margin-left: 18px;
            margin-top: 128px;
        }
    }

    .home-nl {
        &__link {
            width: 190px;
        }
    }

    .home-events {
        margin-top: 60px;
        width: 100%;
        padding-left: 0;
        
        &__container {
            flex-direction: row;
    
            &:after {
                height: 230px;
                width: 330px;
                right: auto; 
                left: 50%;
                top: auto;
                bottom: -145px;
                transform: translateX(-50%);
            }
        }
    
        .featured-event {
            width: 190px;
            height: 206px;
            margin-bottom: 0;
            margin-right: 25px;
            padding: 15px;
    
            &:last-child {
                margin-right: 0;
            }
        }

        &__template-btn {
            display: flex;
            justify-content: space-between;
        }
    
        &__btn {
            margin: 40px 0 0;

            &:last-child {
                text-align: right;
                align-items: flex-end;
            }
        }
    }

    .home-annuaire {
        width: 410px;

        &__title {
            margin: 15px 0 13px;
        }

        &__wrapper-select {
            select {
                width: 340px;
            }
        }
    }

    .home-partners {
        padding-left: 0;
        margin: 50px auto 55px;

        &__list {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;
        } 

        .partner {
            margin-top: 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .home-tetris {
        &--top {
            .home-tetris {
                &__row {
                    padding: 0 0 50px;
    
                    &:after,
                    &:before {
                        height: 1415px;
                    }
                }
            }
        }

        &--bottom {
            padding: 70px 0 0;
        }

        &__row {
            &__half {
                flex-direction: column;
            }
        }

        &__col {
            max-height: 100%;
            flex-direction: column;
            text-align: center;
            padding: 50px 0 60px;

            &:before,
            &:after {
                height: 100%;
            }
        }
    }

    .home-slideshow {
        &__pagination {
            left: 50%;
            transform: translateX(-50%);

            .swiper-pagination-bullet {
                margin: 0 10px;
            }
        }

        .slideshow {
            height: 440px;

            &__content {
                left: 50%;
                transform: translateX(-50%);
                bottom: 65px;
            }
        }
    }

    .home-posts {
        margin-bottom: 90px;

        &__title {
            font-size: 2.5rem;
            text-align: center;
            line-height: 3.375rem;
        }

        &__swiper {
            width: 100%;
        }

        &__pagination {
            justify-content: center;

            .swiper-pagination-bullet {
                margin: 0 10px;
            }
        }

        &__container {
            flex-direction: column;
        }

        .featured-post {
            &__image-container {
                width: 230px;
                margin: auto;
            }

            &__content {
                text-align: center;
            }

            &__more {
                justify-content: center;
            }
        }

        &__btn {
            margin: 30px auto 0;
            width: auto;
            align-items: center;
            text-align: center;
        }
    }

    .home-docs {
        margin-right: 0;
        text-align: center;

        .home-document {
            flex-direction: column;
            align-items: center;

            &__container-image {
                margin: 0 0 30px;
            }

            &__content {
                width: 100%;
            }

            &__buttons {
                justify-content: center;
                margin-top: 30px;
            }

            &__btn {
                margin: 0 10px;
            }
        }   
        
        &__btn-all {
            margin: 30px auto 50px;
            width: auto;
            align-items: center;
            text-align: center;
        }
    }

    .home-nl {
        &__link {
            width: 100%;
            height: 205px;

            span {
                max-width: 250px;
            }

            svg {
                width: 114px;
                height: 94px;
            }
        }
    }

    .home-events {
        margin-top: 70px;

        &__title {
            text-align: center;
        }
        
        &__container {
            flex-direction: column;
    
            &:after {
                height: 465px;
                width: 100%;
                bottom: 90px;
            }
        }
    
        .featured-event {
            margin-bottom: 20px;
            margin-right: auto;
            margin-left: auto;

            &:last-child {
                margin-right: auto;
            }
        }

        &__template-btn {
            flex-direction: column;
        }
    
        &__btn {
            justify-content: center;
            align-items: center;
            margin: 20px auto;
            width: auto;
            text-align: center;

            &:last-child {
                align-items: center;
                text-align: center;
            }
        }
    }

    .home-access {
        &--bottom {
            order: 2;
            margin-right: 0;
            
            .home-access {
                &__list {
                    align-items: center;
                }
            }
        }

        &__title {
            order: 1;
            font-size: 2.5rem;
            line-height: 3.375rem;
            margin: 0 0 40px;
        }
    }

    .home-annuaire {
        order: 3;
        width: 100%;

        &__title {
            margin: 50px 0 10px;
        }

        &__wrapper-select {
            select {
                width: 230px;
                padding: 0 100px 0 15px;
            }
        }

        &__template-btn {
            margin: 15px auto 0;
            justify-content: center;
            align-items: center;
            width: 100%;
            text-align: center;
        }
    }

    .home-partners {
        &__list {
            flex-direction: column;
        }

        .partner {
            margin-bottom: 50px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
