.newsthread {
    position: relative;
    padding: 25px 0 5px;

    &:before {
        content: '';
        position: absolute;
        height: 56px;
        width: 100%;
        top: 0;
        background-color: $color-six ;
        z-index: -1;
    }

    &__swipper {
        /* flex: 1; */
        width: 850px;
        overflow: hidden;
    }

    &__link {
        &:hover {
            text-decoration: underline;
        }
    }

    &__content {
        background-color: $color-white;
        box-shadow: $shadow;
        height: 62px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-right: 13px;
    }

    &__title {
        display: flex;
        align-items: center;
        flex: none;
        margin: 0 20px 0 25px;

        &__text {
            font-size: $font-size--4;
            font-weight: $font-weight-medium;
        }

        svg {
            width: 23px;
            height: 23px;
            margin-right: 20px;
        }
    }

    &__nav {
        display: flex;
        align-items: center;

        &__prev,
        &__next {
            @extend .btn-home--pictos; 
            padding: 0;
            background: transparent;
            margin: 0 0 0 15px;
            height: 36px;
            width: 36px;

            svg {
                height: 36px;
                width: 36px;
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .newsthread {
        &__content {
            padding-right: 25px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .newsthread {
        &__content {
            padding-right: 13px;
        }

        &__title {
            margin: 0 15px 0 20px;

            &__text {
                display: none;
            }

            svg {
                margin-right: 0;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .newsthread {
        &__content {
            height: auto;
            padding: 20px;
            flex-direction: column;
            text-align: center;
        }

        &__title {
            display: none;
        }

        &__swipper {
            width: 260px;
            margin: 0 auto;
        }

        &__nav {
            justify-content: center;

            &__prev,
            &__next {
                margin: 15px 7px 0;
            }
        }
    }
}