#nav-main {
    position: relative;
    z-index: 10;
    padding: 17px 0;
    margin: 0;
    transition: .35s;

    .container--nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .menu__nav__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex: 1;
        transition: $duration ease-out;
    } 

    .nav-main-item {

        &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
            .sub-menu {
                display: flex;
                justify-content: center;
            }
        }

        &.active {
            .nav-main-link {
                &:before {
                    opacity: 1;
                }

                &:after {
                    width: 100%;
                }
            }
        }

        .nav-main-link {
            position: relative;
            display: flex;
            align-items: center;
            height: 75px;
            padding: 15px 0;
            color: $color-text;
            font-size: $font-size--4;
            font-weight: $font-weight-semibold;
            line-height: 1.5rem;
            text-transform: uppercase;

            &:before,
            &:after {
                content: '';
                position: absolute;
                transform: translateX(-50%);
                left: 50%;
                transition: $duration;
            }

            &:before {
                background-color: $color-five;
                width: 79px;
                height: 79px;
                border-radius: $border-radius--round;
                top: 5px;
                z-index: -1;
                opacity: 0;
            }

            &:after {
                bottom: 15px;
                width: 0;
                height: 3px;
                background: $color-third;
            }

            &:hover {
                &:before {
                    opacity: 1;
                }

                &:after {
                    width: 100%;
                }
            }
        }

        .sub-menu {
            display: none;
            position: absolute;
            left: 0;
            width: 100%;
            padding: 30px 15px;
            background-color: $color-white;
            box-shadow: $shadow;

            ul {
                width: 100%;
                column-count: 3;
                column-gap: 30px;
            }

            .nav-child-item, .nav-grandchild-item {
                display: inline-flex;
                width: 100%;
                padding: 0;
                line-height: initial;

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    height: 50px;
                    padding: 5px 15px 5px 0;
                    color: $color-text;
                    font-weight: $font-weight-bold;

                    &::before {
                        content: "";
                        display: inherit;
                        position: absolute;
                        bottom: 0;
                        width: 40px;
                        height: 2px;
                        background: $color-third;
                        transition: all $duration;
                    }

                    &:hover {
                        &::before {
                            width: 100%;
                        }
                    }
                }
            }

            .nav-grandchild-item {
                a {
                    display: flex;
                    padding: 5px 0 5px 20px;
                    border-bottom: 0;
                    height: 48px;
                    font-weight: $font-weight;
                    text-transform: initial;
                    line-height: initial;

                    &::before {
                        background: $color-fourth;
                    }

                    &:hover {
                        &::before {
                            width: calc(100% - 20px);
                        }
                    }
                } 
            }
        }
    }
}

// Responsive button
.nav-main_button {
    display: none;
}

// Responsive button label
.nav-main_icon {
    display: none!important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 20px;
    color: $color-second;

    &:hover {
        color: $color-text;

        .navicon {
            background-color: $color-text;

            &:before, 
            &:after {
                background-color: $color-text;
            }
        }
    }

    .navicon {
        position: relative;
        display: block;
        height: 3px;
        top: 0;
        width: 27px;
        margin-right: 15px;
        background: $color-second;
        transition: all $duration ease-out;

        &:before, 
        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-second;
            transition: all $duration ease-out;
        }

        &:before {
            top: 7px;
        }

        &:after {
            top: -7px;
        } 
    }
}


.header--fixed {
    #nav-main {
        position: fixed;
        top: 0;
        background-color: $color-white;
        width: 100%;
        padding: 0;
        margin: 0;
        box-shadow: $shadow;

        .nav-main-item {
            .nav-main-link {
                height: 70px;
                overflow: hidden;
            }
        }
    }
}

//=================================================================================================
// Responsive
//=================================================================================================
// 1200
@media screen and (max-width: $large) {
    #nav-main {
        .nav-main-item {
            .sub-menu {
                .nav-grandchild-item {
                    a {
                        padding: 5px 0 5px 10px;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    #nav-main {
        position: relative;
        padding: 30px 0;

        .container--nav {
            position: relative;
            justify-content: center;
        }

        .menu__nav__list {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 60px;
            width: 100%;
            background-color: $color-white;
            box-shadow: $shadow;
        }

        .nav-main-item {
            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100%;
                height: 60px;
                padding: 0 20px;
                border-bottom: 1px solid $color-text;
                color: $color-text;
                text-transform: uppercase;

                &:hover {
                    background-color: $color-six;
                }

                &:before {
                    display: none;
                }

                &:after {
                    display: none;
                }
            }

            &:last-child {
                .nav-main-link {
                    border-bottom: 0;

                    &:after {
                        width: 0;
                        height: 0;
                        background: $color-bg--transparent;
                    }
                }
            }

            &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                .sub-menu {
                    display: none;
                }
            }

            &.active,
            &.menu-item-has-children.active {
                background: $color-six;

                .nav-main-link {
                    border-bottom: 1px solid $color-text;
                    color: $color-text;
                }

                .sub-menu {
                    display: flex;
                    position: relative;
                    padding: 5px 10px;
                    box-shadow: none;

                    .nav-drop {
                        column-count: 1;
                    }

                    .nav-grandchild-item {
                        a {
                            padding: 10px;

                            &:hover {
                                &:before {
                                    width: calc(100% - 10px);
                                }
                            }
                        }
                    }
                }
            }

        }

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    color: $color-text;
                    background-color: $color-fourth;

                    .navicon {
                        background-color: transparent;
            
                        &:before, 
                        &:after {
                            background-color: $color-text;
                        }

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex!important;
            width: 220px;
            height: 50px;
            margin: 0;
            padding: 0;
            font-size: 1.625rem;
            font-weight: $font-weight-semibold;
            text-transform: uppercase; 
        }
    }
}


// 640
@media screen and (max-width: $small) {
    #nav-main {
        .container--nav {
            justify-content: space-between;
        }

        .menu__nav__list {
            width: 300px;
        }
    }
}
