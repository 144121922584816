.footer {
    &--top {
        padding: 50px 0;
        background-color: $color-six;
    }

    .footer-infos {
        display: flex;
        justify-content: space-around;
        align-items: center;

        &__title {
            margin: 0 0 5px;
            font-size: $font-size--4;
            font-weight: $font-weight-medium;
        }

        &__text {
            line-height: 1.25rem;
        }

        &__address {
            width: 280px;
            margin-right: 20px;
        }

        &__hours {
            width: 410px;
            margin-right: 50px;
        }

        &__links {
            display: flex;
        }

        &__phone {
            font-weight: $font-weight-bold;

            &:hover {
                span {
                    text-decoration: underline;
                }
            }

            span {
                font-weight: $font-weight-normal;
                transition: $duration
            }
        }

        &__contact {
            @extend .btn-home--shadow;
            width: 200px;
            height: 120px;
            margin-right: 50px;

            &:hover {
                border-color: $color-second;
            }

            svg {
                margin-bottom: 8px;
            }
        }

        &__pictos {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__link {
                @extend .btn-home--pictos;
            }
        }
    }

    &__menu {
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &__link {
            font-size: $font-size--text-small;
            line-height: 1.125rem;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {
    .footer {
        .footer-infos {
            justify-content: space-between;

            &__address {
                width: 220px;
            }
        
            &__hours {
                width: 390px;
                margin-right: 30px;
            }
    
            &__contact {
                margin-right: 30px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        &--top {
            padding: 45px 0 65px;
        }

        .footer-infos {
            flex-direction: column;
            align-items: center;
            text-align: center;

            &__address {
                width: 100%;
                margin-right: auto;
                margin-bottom: 40px;
            }
        
            &__hours {
                width: 100%;
                margin-right: auto;
                margin-bottom: 50px;
            }
        }

        &__menu {
            justify-content: center;
            flex-wrap: wrap;
            height: 120px;
            padding: 20px 0;

            &__item {
                display: flex;
                padding: 0 30px;
            }
        }   
    }
}



// 640
@media screen and (max-width: $small) {
    .footer {
        &__menu {
            height: 150px;
            
            &__item {
                display: flex;
                padding: 5px 10px;
            }
        }   
    }
}