.breadcrumb {
    background-color: $color-white;
    box-shadow: $shadow;
    display: inline-flex;
    margin-top: 50px;

    ul  {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 50px;
        padding: 5px 20px;

        > svg {
            width: 15px;
            height: 20px;
            fill:$color-second;
            margin: 0 15px;
        }
    }

    li {
        display: flex;
        align-items: center;
        color: $color-text;
        font-weight: $font-weight-semibold;
        padding: 5px 0;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        position: relative;

        ul {
            min-height: 40px;
        }
    }

}
