.bloc-content--404 {
    h2 {
        margin: 0;
        border: 0;
        font-size: 12.5rem;
        text-align: center;
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .searchResult {
        border: 1px solid $color-gray;
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}


//======================================================================================================
// Responsive
//======================================================================================================

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            font-size: 10rem;
        }
    }

}
