.cover {
    position: relative;
    margin-top: -420px;
    padding-bottom: 25px;

    > .container {
        display: flex;
        justify-content: flex-end;
    }

    &__image-wrapper {
        width: 700px;
        height: 420px;
        box-shadow: $shadow;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        position: relative;
        padding: 60px 0 0;
        margin-top: 60px;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            height: calc(100% - 25px);
            width: 100%;
            background: $color-six;
            z-index: -1;
        }
    }

    &__title {
        margin: 0 0 20px;
    }

    &__intro {
        color: $color-text;
        font-size: $font-size--4;
        font-weight: $font-weight-medium;
        line-height: 1.5rem;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================
// 1200
@media screen and (max-width: $large) {
    .cover {
        margin-top: -340px;

        &__image-wrapper {
            width: 560px;
            height: 340px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .cover {
        margin-top: 38px;

        > .container {
            justify-content: center;
        }

        &__informations {
            margin-top: -50px;
            padding: 90px 0 0;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .cover {
        > .container {
            max-width: 100%;
            padding: 0;
        }

        &__image-wrapper {
            height: 385px;
            width: 100%;
        }
    }

}
