.infowindow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(47, 47, 47, 0.8);

    &__close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        background-color: $color-dark;
        border-color: $color-dark;
        border-radius: 50%;
        transition: all $duration;

        svg {
            width: 20px;
            height: 20px;
            fill: $color-white;
            transition: all duration;
        }

        &:hover, &:focus {
            background-color: $color-bg--transparent;
            border-color: $color-dark;

            svg {
                fill: $color-text;
            }
        }

        &--back {
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            width: 100%;
            height: 100%;
            background-color: $color-bg--transparent;
            border-radius: 0;
            cursor: initial;

            &:hover, &:focus {
                background-color: $color-bg--transparent;
            }
        }
    }

    .container {
        z-index: 1;
    }

    &__card {
        display: flex;
        align-items: stretch;
        width: 680px;
    }

    &__color {
        width: 10%;
        background-color: $color-bg-alert;
        background-image: url(/wp-content/themes/noyau/assets/images/core/ic-alert.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: 35px;
    }

    &__infos {
        display: block;
        position: relative;
        width: 90%;
        padding: 30px;
        background-color: $color-white;
    }

    &__title {
        font-size: $font-size--2;
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        line-height: $line-height--heading;
        margin-top: 0;
        margin-bottom: 30px;
    }

    &__bloc {
        max-height: 60vh;
        overflow-y: auto;

        .bloc-image, .bloc-video, .bloc-video__wrapper, .bloc-embed_code__iframe {
            margin: 0;
        }

        .external {
            display: flex;
            align-items: flex-end;

            &[target=_blank]::after {
                position: absolute;
                top: auto;
                left: auto;
                right: 0;
                bottom: 0;
                padding: 5px;
                background-color: $color-white;
                color: $color-dark;
            }
        }

        .accordion--transcription {
            margin-top: 15px;
        }
    }

}



//======================================================================================================
// Responsive
//======================================================================================================
  
// 960
@media screen and (max-width: $medium) {

    .infowindow {
    
        &__card {
            width: 100%;
        }

    }
    
}


// 640
@media screen and (max-width: $small) {

    .infowindow {
    
        &__card {
            position: relative;
            flex-wrap: wrap;
        }

        &__color {
            width: 100%;
            height: 62px;
            background-position: 20px center;
        }

        &__infos {
            position: initial;
            width: 100%;
            padding: 20px;
        }

        &__title {
            font-size: $font-size--4;
        }

        &__bloc {
            max-height: 50vh;
        }

        &__close {
            top: 10px;
            right: 20px;
            width: 40px;
            height: 40px;

            &--back {
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        }

    }
    
}
