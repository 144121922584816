.header {
    &__identity {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 201px;
        width: 214px;
        margin-left: 80px;
        z-index: 2;

        &--fixed {
            display: none!important;
        }
 
        &__title {
            font-size: 0;
            line-height: 0;
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
#uci_link{
    display: none;
}

body:not(.home) {
    .tools {
        &:before {
            height: 145px;
            left: 50%;
            width: 5000px;
            transform: translateX(-50%);
        }
    }
}

.tools {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: 35px;
    width: 470px;

    &:before {
        content: '';
        position: absolute;
        background-color: $color-five;
        height: 315px;
        width: 2000px;
        right: 114px;
        top: 0;
        z-index: -1;
    }

    .tool {
        display: flex;
        align-items: center;
    }

    &__btn {
        position: relative;
        width: 170px;
        margin: 35px 0 0;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        z-index: 2;

        &:hover {
            &:after {
                width: calc(100% - 15px);
            }
        }

        &:after {
            content: '';
            display: block;
            background-color: $color-third;
            width: 50px;
            height: 3px;
            margin-top: 17px;
            transition: $duration;
        }

        svg {
            width: 26px;
            height: 26px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
            fill: $color-main;
        }
    }

    &__search {
        position: relative;
        padding: 0;
        justify-content: flex-start;
        width: 385px;
        height: 70px;
        box-shadow: $shadow;
        background-color: $color-white;
        margin: 35px 0 0;
        overflow: hidden;
        text-transform: uppercase;
        font-size: $font-size--4;
        font-weight: $font-weight-semibold;
        color: $color-text;
        border: none;
        z-index: 2;

        &--disabled {
            pointer-events: none;
            opacity: .4;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            border: 2px solid $color-bg--transparent;
            transition: $duration;
        }

        &--fixed {
            display: none!important;

            &:after {
                display: none;
            }
        }

        svg {
            width: 70px;
            height: 70px;
        }

        &:hover {
            background-color: $color-white;
            color: $color-text;
            box-shadow: none;

            &:after {
                border-color: $color-third;
            }
        }
    }

    &__accessibility {
        @extend .btn-home--pictos; 
        margin: 0 0 0 65px;
    }
}

.tools-view {
    position: absolute;
    top: 165px;
    width: 100%;
    z-index: 1;

    .container--tools-view {
        display: flex;
        justify-content: flex-end;

        #google_translate_element {
            display: none;
        }

        .profils {
            position: relative;
            display: none;
            width: 240px;
            height: auto;
            margin-top: 45px;
            margin-right: 60px;
            padding: 15px 20px 20px;
            background: $color-second;

            &.show-list {
                display: flex;
                flex-direction: column;
            }

            &__title {
                color: $color-white;
                text-transform: uppercase;
                margin: 20px 0 15px;
            }

            &__list {
                li {
                    a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        padding: 10px 20px 10px 0px;
                        border-top: 1px solid $color-white;
                        color: $color-white;
                        transition: all $duration;

                        &:hover {
                            padding-left: 10px;
                        }

                        &::after {
                            content: "\f344";
                            font: normal 13px/1 dashicons;
                            display: flex;
                            align-items: center;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: $color-white;
                        }
                    }

                    &:last-child {
                        a {
                            border-bottom: 1px solid $color-white;
                        }
                    }
                }
            }
        }
    }
}

iframe.goog-te-menu-frame.skiptranslate {
    position: fixed;
    top: 230px !important;
    left: calc(100% - ((100% - 1200px) / 2) - 335px) !important;
}

.header--fixed {
    .tools__accessibility {
        display: none;
    }

    .tools__search--fixed {
        margin: 0 0 0 45px;
        display: flex!important;
        box-shadow: none;
    }

    .header__identity--fixed {
        display: flex!important;
        margin-right: 45px;
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        border-color: $btn-privacy-border-color;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $btn-privacy-bg--hover;
            border-color: $btn-privacy-border-color--hover;
            color: $btn-privacy-color--hover; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 960px)/2 - 335px)!important;
        top: 230px!important;
    }

    
    .tools {
        width: 380px;
        margin-top: 20px;

        &:before {
            height: 285px;
            right: 60px;
        }

        &__accessibility {
            margin: 0 0 0 20px;
        }

        &__search {
            width: 340px;
            margin: 25px 0 0;
            font-size: 1rem;

            svg {
                height: 50px;
                width: auto;
                margin-right: 20px;
            }
        }

        &__btn {
            width: 150px;
            margin: 25px 0 0;

            svg {
                margin-right: 10px;
            }
        }
    }

    .header {
        &__identity {
            width: 167px;
            height: 153px;
            margin-left: 60px;

            svg {
                width: 167px;
                height: 156px;
            }
        }

        &--fixed {
            .tools {
                &__accessibility {
                    margin: 0 0 0 15px;
                }
                
                &__search--fixed {
                    margin: 0 15px 0;
                    
                    svg {
                        margin-right: 0;
                    }
                }
            }

            .header__identity--fixed {
                margin-right: 15px;
            }
        }
    }

    body:not(.home) {
        .tools {
            &:before {
                height: 125px;
            }
        }
    }

    .header--fixed {
        .tools__accessibility {
            margin: 0 0 0 15px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute;
        left: calc(100% - (100% - 640px)/2 - 335px)!important;
        top: 240px!important;
    }

    .privacy {
        &__container {
            flex-wrap: wrap;
        }

        &__button {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .header {
        &__identity {
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }

    .tools {
        width: 340px;
        margin: 30px auto 0;
        justify-content: center;

        &:before {
            right: auto;
            height: 430px;
            left: 50%;
            transform: translateX(-50%);
        }

        &__search {
            svg {
                height: 70px;
            }
        }
    }

    
    body:not(.home) {
        .tools {
            &:before {
                height: 272px;
            }
        }
    }
  
}


// 640
@media screen and (max-width: $small) {

    iframe.goog-te-menu-frame.skiptranslate {
        position: absolute!important;
        left: calc(100% - (100% - 300px/2))!important;
        top: 280px!important;
    }

    .tools {
        width: 100%;

        &__search {
            svg {
                margin-right: 10px;
                margin-left: -10px;
            }
        }
    }

    
    body:not(.home) {
        .tools {
            &:before {
                height: 205px;
            }
        }
    }

}
